import React, {useState} from 'react';
import Header from './shared/Header';
import { Link } from 'react-router-dom';
import Suppliers from './shared/Suppliers';
import Footer from './shared/Footer';
import ScrollToTop from './shared/ScrollToTop';
import FloatUpComponent from './shared/FloatUpComponent';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
// for lightbox
import PhotoAlbum from "react-photo-album";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import photos from "./shared/photos.ts";

function Home() {
    const Adaptability = 95;
    const Accountability = 80;
    const Teamwork = 100;
    const modeling = 75;
    const [index, setIndex] = useState(-1);
    return (
        <>
            <ScrollToTop />
            <Header id='1' />
            <section id="banner" class="carousel slide" data-bs-ride="carousel" data-bs-interval="10000">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div className="landing-banner">
                            <img className="banner-image" src="/assets/images/banner1.jpg" alt="banner"/>
                            <div className="landing-overlay">
                                <h6 className="banner-heading drop-in">MAKE A LASTING FIRST IMPRESSION</h6>
                                <p className='banner-content drop-in-2'>Capture your client's attention from the very beginning with our top-quality reception counters,<br/>customized furniture, unique lift flooring designs, modern bathroom vanities,<br/> stylish wall claddings, retail countertops, and more.</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div className="landing-banner">
                            <img className="banner-image" src="/assets/images/banner2.jpg" alt="banner"/>
                            <div className="landing-overlay">
                                <h6 className="banner-heading drop-in">Impress Your Guests with Unparalleled Elegance!</h6>
                                <p className="banner-content drop-in-2">
                                Transform your guests’ experience with our exceptional selection of furniture, elegant table tops,<br/> diverse sink options, and stunning wall claddings. Discover a world of refinement and<br/> sophistication that leaves a lasting impression
                                </p>
                            </div>
                        </div> 
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#banner" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#banner" data-bs-slide="next">
                    <span class="carousel-control-next-icon"></span>
                </button>
            </section>
            <FloatUpComponent>
            </FloatUpComponent>            
            <section className='section alternate-bg '>
                <FloatUpComponent>
                    <div className='container'>
                        <h3 className='heading'>About Us</h3>
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-md-6'>
                                <h6 className='sub-heading drop-in'>Commitment to Exceptional Quality</h6> 
                                <p className='paragraph drop-in-2'>
                                Transform your home or workplace with the elegance and sophistication you've been longing for. Now is the perfect time to make a lasting impression with our premium range of furnishings. Kings Fabrications team blends meticulous craftsmanship with a refined selection of living, bathroom, kitchen, and vanity solutions, providing you with unmatched quality and style.
                                </p>
                            </div>
                            <div className='col-md-6'>
                                <div className='about-image-box'>
                                    <img src='/assets/images/about.jpeg' className='about-image' alt="about"/>
                                    <img src='/assets/images/about.jpeg' className='about-image-2' alt="about"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </FloatUpComponent>
            </section>
            <section className='section'>
                <FloatUpComponent>
                    <div className='container'>
                        <h3 className='heading'>Services</h3>
                        <p className='paragraph text-center'>
                        Our team is dedicated to turning your vision into reality through custom solid surface designs and a diverse selection of furniture and bathroom vanity solutions. Improving your home and workspace has never been more seamless. We are here to support you every step of the way.
                        </p>
                        <div className='specialization'>
                            <div className='specialization-columns'>
                                <div className='column-icons'>
                                    <img src='/assets/images/s4.png' alt='icon' />
                                </div>                                
                                <h6 className='column-title'>Reception Area</h6>
                                <p className='paragraph'></p>
                            </div>
                            <div className='specialization-columns'>
                                <div className='column-icons'>
                                    <img src='/assets/images/kitchen_countertop.png' alt='icon' />
                                </div>
                                <h6 className='column-title'>Kitchen Spaces</h6>
                                <p className='paragraph'></p>
                            </div>
                            <div className='specialization-columns'>
                                <div className='column-icons'>
                                    <img src='/assets/images/s2.png' alt='icon' />
                                </div>
                                <h6 className='column-title'>Bathroom Vanities</h6>
                                <p className='paragraph'></p>
                            </div>
                        </div>
                        <div className='text-center position-relative'>
                            <Link to='/services' className="buttons">View All  <i class="fa-solid fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </FloatUpComponent>
            </section>
            <section className='section company-value'>
                <div className='container'>
                    <h3 className='heading'>The Core Company Values</h3>
                    <div className='value-flex'>
                        <div className='value-columns'> 
                            <div className='progress-bar-container'>
                                <CircularProgressbar value={Adaptability} text={`${Adaptability}%`} /> 
                            </div>
                                                  
                            <h4 className='value-heading'>Adaptability</h4>
                        
                        </div>
                        <div className='value-columns'>
                            <div className='progress-bar-container'>
                                <CircularProgressbar value={Accountability} text={`${Accountability}%`} /> 
                            </div>
                            <h4 className='value-heading'>Accountability</h4>
                        </div>
                        <div className='value-columns'>
                            <div className='progress-bar-container'>
                                <CircularProgressbar value={Teamwork} text={`${Teamwork}%`} /> 
                            </div>
                            <h4 className='value-heading'>Teamwork</h4>
                        </div>
                        <div className='value-columns'>
                            <div className='progress-bar-container'>
                                <CircularProgressbar value={modeling} text={`${modeling}%`} /> 
                            </div>
                            <h4 className='value-heading'>Compassion</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section alternate-bg '>
                <FloatUpComponent>
                    <div className='container'>
                        <h3 className='heading'>Gallery</h3>
                        <PhotoAlbum photos={photos} columns={7} layout="rows" targetRowHeight={300} onClick={({ index }) => setIndex(index)} />
                        <Lightbox
                            slides={photos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                        /> 
                    </div>
                </FloatUpComponent>
            </section>
            <Suppliers/>
            <div className='text-center position-relative mb-5'>
                <Link to='/contact-us' className="buttons">Work Together  <i class="fa-solid fa-arrow-right"></i></Link>
            </div>
            <Footer />
        </>
    );
}
export default Home;