import React from 'react';
import FloatUpComponent from './FloatUpComponent';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Suppliers() {
    const responsive = {
        largedesktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1199, min: 768 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 767, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    };
    return (
        <section className='section'>
            <FloatUpComponent>
                <div className='container'>
                    <h3 className='heading'>OUR SUPPLIERS</h3>
                    <Carousel
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    >
                        <div className='supplier-boxs'>
                            <img className='supplier-images' src="/assets/images/corian.png" alt='supplier'/>
                        </div>
                        <div className='supplier-boxs'>
                            <img className='supplier-images' src="/assets/images/hanex.png" alt='supplier'/>
                        </div>
                        <div className='supplier-boxs'>
                            <img className='supplier-images' src="/assets/images/himacs.png" alt='supplier'/>
                        </div>
                        <div className='supplier-boxs'>
                            <img className='supplier-images' src="/assets/images/staron.png" alt='supplier'/>
                        </div>
                    </Carousel>
                </div>
            </FloatUpComponent>
        </section>
    );
}
export default Suppliers;