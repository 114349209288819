import React from 'react';
import { Link } from 'react-router-dom';
function Breadcrumb(props) {
    return (
        <div className='breadcrumbs'> 
            <div className='bread-overlay'>
                <div className='container'>
                    <h4 className='breadcrumb-title'>{props.title}</h4>
                    <div>
                        <Link className='breadcrumb-link' to={props.parent_slug}>{props.parent}</Link> &nbsp;&nbsp; /  &nbsp;&nbsp; 
                        <span className='breadcrumb-text'>{props.title}</span> 
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Breadcrumb;