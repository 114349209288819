import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Suppliers from './shared/Suppliers';
import Breadcrumbs from './shared/Breadcrumb';
import ScrollToTop from './shared/ScrollToTop';
import FloatUpComponent from './shared/FloatUpComponent';
// for lightbox
import PhotoAlbum from "react-photo-album";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import photos from "./shared/photos.ts";

function Projects() {
    const [index, setIndex] = useState(-1);
    console.log(photos, 'manish')
    return (
        <>
            <ScrollToTop />
            <Header id='4' />
            <Breadcrumbs title="projects" parent="Home" parent_slug="/"/>
            <div className='section'>
                <FloatUpComponent>
                    <div className='container'>
                        <h4 className='heading'>Our Projects</h4>
                        <PhotoAlbum photos={photos} layout="rows" columns={5} targetRowHeight={500} onClick={({ index }) => setIndex(index)} />
                        <Lightbox
                            slides={photos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                        />      
                    </div>
                </FloatUpComponent>
            </div>
            <Suppliers/>
            <div className='text-center position-relative mb-5'>
                <Link to='/contact-us' className="buttons">Work Together  <i class="fa-solid fa-arrow-right"></i></Link>
            </div>
            <Footer />
        </>
    );
}
export default Projects;