function ServiceCard(props) {
    return (
        <div className='services-columns'>
            <div className="service-inner">
                <div className={'icon-box'}>
                    <img src={'/assets/images/' + props.img} alt='icon'/>
                </div>
                <div class="service-content">
                    <h6 className='service-title'>{props.title}</h6>
                    <p className="content paragraph">{props.description}</p>
                </div>
            </div>
        </div>
    );
  }

  export default ServiceCard;
