import React from 'react';
import { Link } from 'react-router-dom';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Suppliers from './shared/Suppliers';
import Breadcrumbs from './shared/Breadcrumb';
import ScrollToTop from './shared/ScrollToTop';

function About() {
    return (
        <>
            <ScrollToTop />
            <Header id='2' />
            <Breadcrumbs title="About us" parent="Home" parent_slug="/"/>
            <div className='section'>
                <div className='container'>
                    <div className='row m-0 p-0 align-items-center'>
                        <div className='col-lg-6'>
                            <h4 className='sub-heading drop-in'>Transforming Spaces, Elevating Lives.</h4>
                            <p className='paragraph drop-in-2'>At Kings Fabrications, we don’t just enhance your personal and professional environments—we bring them to life. With over a decade of expertise in the UAE’s fabrication industry, we are committed to delivering more than just satisfaction; we strive for perfection in every project.</p>
                            <p className='paragraph drop-in-2'>Our team, selected for their proven craftsmanship and attention to detail, approaches each task with the artistry and precision that turn visions into reality. Whether crafting a bespoke vanity or designing a sleek, modern kitchen island, we embody our tagline as the Masters of Solid Surfaces, executing each project with passion and meticulous care.</p>
                            <h4 className='sub-heading drop-in'>OUR VISION</h4>
                            <p className='paragraph drop-in-2'>We believe that everyone deserves a beautifully designed office and home. That’s why we offer tailor-made furniture and bathroom vanity solutions that not only meet your budget but exceed your expectations. With our expertise and dedication, we create spaces that inspire.</p>
                        </div>
                        <div className='col-lg-6'>
                            <img src='/assets/images/about-page.png' className='about-page-image' alt='about' />
                        </div>
                    </div>                    
                </div>
            </div>
            <Suppliers/>
            <div className='text-center position-relative mb-5'>
                <Link to='/contact-us' className="buttons">Work Together  <i class="fa-solid fa-arrow-right"></i></Link>
            </div>
            <Footer />
        </>
    );
}
export default About;