const unsplashLink = (id: string, width: number, height: number) =>
  `assets/gallery/${id}`;
const unsplashPhotos:any = [];
for(let i = 1; i <= 17; i++){
  const item = {
    id: "g"+i+'.jpg',
    width: 480,
    height: 880
  }
  unsplashPhotos.push(item);
}

const photos = unsplashPhotos.map((photo) => {
  const width = photo.width;
  const height = photo.height;

  return {
    src: unsplashLink(photo.id, width, height),
    width,
    height,
    srcSet: [{
      height: height,
      width: width,
      src: unsplashLink(photo.id, width, height)
    }]
  };
});

export default photos;