import React from 'react';
import { Link } from 'react-router-dom';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Suppliers from './shared/Suppliers';
import ServiceCard from './shared/ServiceCard';
import Breadcrumbs from './shared/Breadcrumb';
import ScrollToTop from './shared/ScrollToTop';
import FloatUpComponent from './shared/FloatUpComponent';

function Services() {
    return (
        <>
            <ScrollToTop />
            <Header id='3' />
            <Breadcrumbs title="services" parent="Home" parent_slug="/"/>
            <div className='section'>
                <div className='container'>
                    <h4 className='sub-heading drop-in text-center'>Choose or customize- We're right here!</h4>
                    <p className='paragraph drop-in-2 text-center'>Whether you're selecting from our range of options or seeking a custom solution, we're just a call away. Our knowledgeable team is ready to help you find the perfect fit for your home or workplace, offering personalized recommendations and tailored customizations to meet your unique needs.</p>                   
                </div>
            </div>
            <div className='section alternate-bg'>
                <FloatUpComponent>
                    <div className='container'>
                        <h4 className='heading'>Our Services</h4>
                        <div className={'services'}>                        
                            <ServiceCard img='kitchen_countertop.png' title='KITCHEN COUNTER TOPS' description="" />
                            <ServiceCard img='s2.png' title='BATHROOM VANITIES' description=""/>
                            <ServiceCard img='s3.png' title='SHOWER ENCLOSURE' description=""/>
                            <ServiceCard img='s4.png' title='RETAIL COUNTERTOPS' description=""/>
                            <ServiceCard img='s5.png' title='TABLE TOPS' description=""/>
                            <ServiceCard img='s6.png' title='COLUMNS' description=""/>
                            <ServiceCard img='customized_furniture.png' title='CUSTOMIZED FURNITURE' description=""/>
                            <ServiceCard img='wall-cladding.png' title='WALL CLADDING AND FURNITURES' description=""/>
                            <ServiceCard img='s9.png' title='DECORATIVE ELEMENTS' description=""/>
                            <ServiceCard img='s10.png' title='OTHER CUSTOM FABRICATION TECHNOLOGIES' description=""/>

                            
                        </div>            
                    </div>
                </FloatUpComponent>
            </div>
            <Suppliers/>
            <div className='text-center position-relative mb-5'>
                <Link to='/contact-us' className="buttons">Work Together  <i className="fa-solid fa-arrow-right"></i></Link>
            </div>
            <Footer />
        </>
    );
}
export default Services;