import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Breadcrumbs from './shared/Breadcrumb';
import ScrollToTop from './shared/ScrollToTop';

function PrivacyPolicy() {
    return (
        <>
            <ScrollToTop />
            <Header />
            <Breadcrumbs title="Privacy Policy" parent="Home" parent_slug="/"/>
            <div className='section'>
                <div className='container'>
                    <h4 className='sub-heading'>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</h4>
                    <p className='paragraph'>We consider the privacy and protection of our clients' data, both corporate and personal, to be of utmost importance and we take robust measures to protect the security and integrity of all such information.</p>
                    <p className='paragraph'>However, the law requires us to take particular steps in relation to the processing and protection of personal data. To ensure that you feel confident about providing us with your personal information when communicating with us and using our services, we outline below our practices in relation to the collection and use of personal data. This policy sets out the basis on which we will collect, use and process personal data. Please read this Privacy Policy carefully to ensure you understand how we handle your personal data; please also check this page from time to time as the Policy may be updated.</p>
                    <h4 className='sub-heading'>INFORMATION WE MAY COLLECT FROM YOU</h4>
                    <p className='paragraph'>We may collect and process the following information about you:</p>
                    <p className='paragraph'>
                    Information you provide by filling in forms on our website and information given in hard copy form or by any other means providing: your name; date of birth; address; interests; marketing preferences; when appropriate your credit information; and any other personal information relevant to the services we provide. This information may be provided when you are registering interest, transacting with us, subscribing to our services or for newsletters or requesting information</p>
                    <p className='paragraph'>Correspondence or other contacts you may have with us</p>
                    <p className='paragraph'>Details of transactions you carry out through our website, and any communications by telephone</p>
                    <p className='paragraph'>Details of your visits to our website including, but not limited to, traffic data, web logs or other communication data and the resources you access.</p>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default PrivacyPolicy;