import React from 'react';
import {Link} from 'react-router-dom';

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <>
        <footer className='footer'>
            <div className='container'>
                <div className='row m-0 p-0'>
                    <div className='col-lg-7'>
                        <div className='row m-0 p-0'>
                            <div className='col-sm-8'>
                                <Link className="footer-logo" to='/'>
                                    <img className='logo' src="/assets/images/logo.png" alt="kingsdxb"/> 
                                    {/* <span className='logo'>Kingsdxb</span> */}
                                </Link>
                                <p className='paragraph'>
                                    Our dedicated team at Kingsdxb, are not only a set of diligent workers but also offer some impeccable range of living, bathroom, kitchen and bathroom vanity solutions.
                                </p>
                                <p className='footer-contact'><span className='footer-icon'><i className="fa-solid fa-phone"></i></span> +971 56 264 2143</p>
                                <p className='footer-contact'><span className='footer-icon'><i className="fa-solid fa-location-dot"></i></span>  Al Quoz Industrial Area 2 ,318th Road - Al Asayel St - Dubai</p>
                            </div>
                            <div className='col-sm-4'>
                                <h3 className='sub-heading'>Quick Links</h3>
                                <ul className="footer-menu">
                                    <li className="footer-item">
                                        <Link className={'footer-link'} to='/'>Home</Link>
                                    </li>
                                    <li className="footer-item">
                                        <Link className={'footer-link'} to='/about-us'>About Us</Link>
                                    </li>
                                    <li className="footer-item">
                                        <Link className={'footer-link'} to='/services'>Services</Link>
                                    </li>    
                                    <li className="footer-item">
                                        <Link className={'footer-link'} to='/projects'>Projects</Link>
                                    </li> 
                                    <li className="footer-item">
                                        <Link className={'footer-link'} to='/contact'>Contact</Link>
                                    </li> 
                                    <li className="footer-item">
                                        <Link className={'footer-link'} to='/privacy-policy'>Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5'>                        
                        <form>
                            <div className='row m-0 p-0'>
                                <div className='col-sm-12'>
                                    <h3 className='sub-heading'>Get In Touch</h3>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='input-box'>
                                        <input className='inputs' name="name" placeholder='Name*'/>
                                        {/* <p className='form-Error text-danger'>Please Enter Name</p> */}
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='input-box'>
                                        <input className='inputs' name="phone" placeholder='Phone'/>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='input-box'>
                                        <input className='inputs' name="email" placeholder='Email*'/>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='input-box'>
                                        <input className='inputs' name="company" placeholder='Company'/>
                                    </div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className='input-box'>
                                        <textarea className='inputs' rows='3' name="email" placeholder='Message'></textarea>
                                    </div>
                                </div>
                                <div className='col-sm-12 text-center position-relative'>
                                    <button type="submit" className="buttons">Submit  <i class="fa-solid fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
        <div className='copyright'>
            <p className='copy-text'>© Kingsdxb {currentYear}. All Rights Reserved.</p>
        </div>
        </>
    );
}
export default Footer;