import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Suppliers from './shared/Suppliers';
import Breadcrumbs from './shared/Breadcrumb';
import ScrollToTop from './shared/ScrollToTop';
import FloatUpComponent from './shared/FloatUpComponent';

function Contact() {
    return (
        <>
            <ScrollToTop />
            <Header id='5' />
            <Breadcrumbs title="contact" parent="Home" parent_slug="/"/>
            <div className='section'>
                <FloatUpComponent>
                    <div className='container'>
                        <div className='contact-flex'>
                            <div className="contact-box">
                                <div className="icon">
                                    <img className='phone' alt='icon' src='/assets/images/phone-black.png' />
                                </div>
                                <div className="contact-content">
                                    <h4>Phone</h4>
                                    <a href="tel:+971 56 264 2143" className='links mb-0'>+971 56 264 2143</a>
                                </div>
                            </div>
                            <div className="contact-box">
                                <div className="icon">
                                    <img alt='icon' className='email' src='/assets/images/email-black.png' />
                                </div>
                                <div className="contact-content">
                                    <h4>E-mail</h4>
                                    <a href="mailto:Admin@kingsdxb.com" className='links'>Admin@kingsdxb.com</a>
                                </div>
                            </div>
                            <div className="contact-box">
                                <div className="icon">
                                    <img alt='icon' className='home' src='/assets/images/home-black.png' />
                                </div>
                                <div className="contact-content">
                                    <h4>Location</h4>
                                    <p className='links mb-0'>Al Quoz Industrial Area 2 ,318th Road - Al Asayel St - Dubai</p>
                                </div>
                            </div>                
                        </div>
                                  
                    </div>
                </FloatUpComponent>
            </div>
            <div>
                {/* <MapComponent/> */}
                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.8692297510943!2d55.243337200000006!3d25.1401116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69dc72eeb89d%3A0xb90eb59d73bf1d59!2sKings%20Fabrication%20Technical%20services%20LLC!5e0!3m2!1sen!2sin!4v1723043523118!5m2!1sen!2sin" className='map-frame' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <Suppliers/>
            <Footer />
        </>
    );
}
export default Contact;