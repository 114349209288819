import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

function Header(props) {
    const [headerActiveClass, setHeaderActiveClass] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 30) {
                setHeaderActiveClass(true);
            } else {
                setHeaderActiveClass(false);
            }
        });
    }, []);
    return (
        <>
        <header className={'header fixed-top ' + (headerActiveClass ? 'active' : '')}>
            <nav className="navbar navbar-expand-md navbar-light bg-white">
                <div className="container">
                    <Link className="navbar-brand" to='/'>
                        <img className='logo' src="/assets/images/logo.png" alt="kingsdxb"/>  
                        {/* <span className='logo'>Kingsdxb</span> */}
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className={'nav-link ' + (props.id === "1" ? 'active' : '')} to='/'>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={'nav-link ' + (props.id === "2" ? 'active' : '')} to='/about-us'>About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={'nav-link ' + (props.id === "3" ? 'active' : '')} to='/services'>Services</Link>
                        </li>    
                        <li className="nav-item">
                            <Link className={'nav-link ' + (props.id === "4" ? 'active' : '')} to='/projects'>Projects</Link>
                        </li> 
                        <li className="nav-item">
                            <Link className={'nav-link ' + (props.id === "5" ? 'active' : '')} to='/contact'>Contact</Link>
                        </li> 
                    </ul>
                    </div>
                </div>
            </nav>
        </header>
        <div className='header-space'></div>
        </>
    );
}
export default Header;